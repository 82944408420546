import { Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { downloadInvoice, invoiceList } from "./InvoiceFun";
import { MdOutlineFileDownload } from "react-icons/md";
// import { APIURL } from "../../../ApiServices/Axios";
import { getLocalStorageItem } from "../../../Utils";
import { saveAs } from "file-saver";
import axios from "axios";

const Invoice = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const InvoiceColumn = [
    {
      title: "Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text) => <p className="font-medium ">{text}</p>,
    },
    {
      title: "Member Id",
      dataIndex: "member_id",
      key: "member_id",
      render: (text) => <p className="font-medium w-[150px]">{text}</p>,
    },
    {
      title: "Invoice Id",
      dataIndex: "invoice_id",
      key: "invoice_id",
      render: (text) => <p className="font-medium w-[150px]">{text}</p>,
    },
    {
      title: "Amount",
      dataIndex: "total_amt",
      key: "total_amt",
      render: (text) => <p className="font-medium w-[150px]">{text} Rs.</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <>
            {text === "1" ? (
              <Tag color="green">Success</Tag>
            ) : text === "2" ? (
              <Tag color="red">Failed</Tag>
            ) : (
              <Tag color="yellow">Pending</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Download",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <div
          onClick={() => {
            setPageLoader(true);
            const formdata=new FormData()
            formdata.append("invoice_id",record.invoice_id)
            axios
              .post(
                `https://${window?.location?.host.split('.').slice(1).join('.')}/` + "Api/V1/Member/downloadInvoice",
               formdata,
                {
                  responseType: "blob",
                  headers: {
                    "Content-Type": "application/pdf",
                    // Range: "bytes=0-999999",
                    Authorization: `Bearer ${getLocalStorageItem(
                      "access_token"
                    )}`,
                  },
                }
              )
              .then((res) => {
                saveAs(res.data, "Invoice.pdf");
              })
              .catch((err) => console.log(err))
              .finally(() => setPageLoader(false));
          }}
          className={` ${"bg-primary cursor-pointer"} rounded-full flex justify-center items-center w-8 h-8`}
        >
          <MdOutlineFileDownload color="white" />
        </div>
      ),
    },
  ];
  const getList = () => {
    setPageLoader(true);
    invoiceList()
      .then((res) => {
        if (res.status) {
          setListData(res.invoiceData);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("120"));
    getList();
  }, []);
  return (
    <>
      <div className="mt-4">
        <p className="my-2 font-bold text-lg">Invoices</p>
        <Spin spinning={pageLoader}>
          <Table
            className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
            columns={InvoiceColumn}
            pagination={false}
            dataSource={listData}
          />
        </Spin>
      </div>
    </>
  );
};

export default Invoice;
