export const Authendpoint = {
  Login: "Auth/login",
  checkPasswordStatus: "Auth/checkPasswordStatus",
  sendpasswordOtp: "Auth/sendPasswordOtp",
  setPasswordAndPin: "Auth/setPasswordAndPin",
  Logout: "Auth/logout",
  VeriFyPin: "Auth/verifyPin",
  sendResetPasswordOtp: "Auth/sendOtp",
  resetPassword: "auth/resetPassword",
  resetPin: "auth/resetPin",
  register: "auth/register",
  getCountryAuthless: "auth/countries",
  getStateAuthLess: "auth/getStates",
  getCityAuthLess: "auth/getCities",
  getMmberTypeAuthLess: "auth/memberTypes",
};
export const Globalendpoints = {
  Dashboard: "Api/V1/dashboard",
  Profile: "Api/V1/Member/profile",
  changePassword: "Api/V1/Member/changePassword",
  changePin: "Api/V1/Member/changePin",
  getwalletBalance: "Api/V1/Member/getWalletBalance",
  getCountry: "Api/V1/Member/countries",
  getState: "Api/V1/Member/getStates",
  getCity: "Api/V1/Member/getCities",
  getBanks: "Api/V1/Verification/getBanks",
  addBankAccount: "Api/V1/Verification/addBankAccount",
  getMemberServices: "Api/V1/Member/memberServices",
  getWalletReport: "Api/V1/Member/walletReport",
  getFundReport:"Api/V1/AddFund/addfundReport",
  checkKycStatus: "Api/V1/DigioKyc/checkKycStatus",
  getTransactionOtp: "Api/V1/Member/getTransactionOtp",

  //FUND REQUESTS
  submitFundRequest: "Api/V1/Member/submitfundRequest",
  adminBankDetail: "Api/V1/Member/adminBankDetails",
  // RECHARGE ENDPOINTS
  operatorAndCircle: "Api/V1/Recharge/opratorAndCircle",
  rechargePlans: "Api/V1/Recharge/plans",
  getOperatorAsNumber: "Api/V1/Recharge/operator",
  recharge: "Api/V1/Recharge/recharge",
  rechargeStatus: "Api/V1/Recharge/checkRechargeStatus",

  //dth operator
  operatorAndCircleDTH: "Api/V1/Recharge/getDthOperator",

  //FASTAG operator
  OperatorAndCircleFASTAG: "Api/V1/Recharge/getFastagOperator",
    //BBSP API'S
    bbspsOperator:"Api/V1/Bbps/operators/",
    bbpsFormParams:"Api/V1/Bbps/formParameters/",
    fetchBill:"Api/V1/Bbps/fetchBill/",
    paybill:"Api/V1/Bbps/billPayment/",
    
    //DMT API'S
    submitKyc:"Api/V1/Dmt/submitKyc",
    verifyKycOtp:"Api/V1/Dmt/verifyOtp",
    getRemitters:"Api/V1/Dmt/getRemitter",
    AddRemiter:"Api/V1/Dmt/remitterRegisteration",
    remitterKyc:"Api/V1/Dmt/remitterKyc",
    verifyRemitterOtp:"Api/V1/Dmt/verifyRemitterOtp",
    dmtBanks:"Api/V1/Dmt/getDmtBanks",
    benificiaryReg:"Api/V1/Dmt/beneficiaryRegistration",
    verifyBenificiary:"Api/V1/Dmt/verifyBeneficiaryOtp",
    beneficiaryDelete:"Api/V1/Dmt/beneficiaryDelete",
    generatePayDmtOtp:"Api/V1/Dmt/generateOtp",
    verifyBeneficiaryDelete:"Api/V1/Dmt/verifyBeneficiaryDeleteOtp",
    fundTransfer:"Api/V1/Dmt/fundTransfer",
    //PAYOUT API'S
    getPayoutBanks:"Api/V1/Payout/getPayoutBanks",
    payoutVerification:"Api/V1/AccountVerification/verifyBankAccount",
    payAmount:"Api/V1/Payout/payout",
    generateQr:"Api/V1/Upi/generateUpi",
    checkUpiPaymentstatus:"Api/V1/Upi/checkUpiPaymentStatus",
    UpiDmt:"Api/V1/UpiDmt/upiDmt",
    // EXPRESSPAYOUT
    getXpressBeneficiary:"Api/V1/XpressPayout/getBeneficiaries",
    XpressPAyout:"Api/V1/XpressPayout/xpressPayout",
    OtpForExpress:"Api/V1/Member/getTransactionOtp",
    //UTI AUTOLOGIN
    utliLogin:"Api/V1/Uti/autoLogin",

    //NSDL SERVICE
    nsdlrequest:"Api/V1/Nsdl/",
    checkPanStatus:"Api/V1/Nsdl/checkPanStatus",
    
    // KYC REQUEST
    DigioKyc:"Api/V1/DigioKyc/generateDigioRequest",
    
    //AEPS ONBOARD
    getMasters:"Api/V1/AepsIcici/masters",
    getCashDepositMaster:"Api/V1/AepsIcici/getCashDepositMaster",
    merchantOnboard:"Api/V1/AepsIcici/merchantOnboard",
    sendOtp:"Api/V1/AepsIcici/sendOtp",
    validateOtp:"Api/V1/AepsIcici/validateOtp",
    resendOtp:"Api/V1/AepsIcici/resendOtp",
    biometric:"Api/V1/AepsIcici/biometric",
    twoFactorauth:"Api/V1/AepsIcici/twoFactorAuth",
    cashwithdrawl:"Api/V1/AepsIcici/cashWithdrawl",
    balanceEnquiry:"Api/V1/AepsIcici/balanceEnquiry",
    cashDeposit:"Api/V1/AepsIcici/cashDeposit",
    miniStatement:"Api/V1/AepsIcici/miniStatement",
    aadharPay:"Api/V1/AepsIcici/aadharPay",
    
    // AEPS YES BANK
    memberUploadDoc:"Api/V1/AepsYesbank/uploadDocument",
    memberkyc:"Api/V1/AepsYesbank/memberKyc",
    getKycData:"Api/V1/AepsYesbank/getKycData",
    getYesMasters:"Api/V1/AepsYesbank/masters",
    yesMerchantOnboard:"Api/V1/AepsYesbank/merchantOnboard",
    yesValidateOtp:"Api/V1/AepsYesbank/validateOtp",
    yesGetAadharWadh:"Api/V1/AepsYesbank/getAadhaarWadh",
    yesBiometric:"Api/V1/AepsYesbank/biometricKyc",
    yesTwoFactorAuth:"Api/V1/AepsYesbank/twoFactorAuth",
    yesCahwithdrawalTwoFa:"Api/V1/AepsYesbank/twoFactorAuthCashWithdrawal",
    yesCashwithdrawl:"Api/V1/AepsYesbank/cashWithdrawal",
    yesBalanceEnquiry:"Api/V1/AepsYesbank/balanceEnquiry",
    yesMiniStatement:"Api/V1/AepsYesbank/miniStatement",
    // MEMBERS
    addmember:"Api/V1/Member/memberSubmit",
    getmemberType:"Api/V1/Member/memberTypes",
    getPackages:"Api/V1/Member/getPackages",
    memberList:"Api/V1/Member/members",
    
    //REPORTS
    aadharpayReport:"Api/V1/AepsIcici/aadharPayReport",
    miniSattementReport:"Api/V1/AepsIcici/miniStatementReport",
    cashwithdrawlReport:"Api/V1/AepsIcici/cashWithdrawlReport",
    cashDepositReport:"Api/V1/AepsIcici/cashDepositReport",
    utiReport:"Api/V1/uti/utiReport",
    payoutReport:"Api/V1/Payout/payoutReport",
    dmtReport:"Api/V1/Dmt/dmtReport",
    bbpsreport:"Api/V1/Bbps/bbpsReport",
    rechargeReport:"Api/V1/Recharge/rechargeReport",
    fundRequestReport:"Api/V1/Member/fundRequestReport",
    upiReport:"Api/V1/Upi/upiReport",
    xpressPayoutReport:"Api/V1/XpressPayout/xpressPAyoutReport",
    balanceEnquireyReport:"Api/V1/AepsIcici/balanceEnquiryReport",
    nsdlReport:"Api/V1/Nsdl/nsdlReport",
    yesBankReport:"Api/V1/AepsYesbank/aepsReport",
    upiDmtReport:"Api/V1/UpiDmt/upiDmtReport",
    MAtmReport:"Api/V1/Matm/matmReport",
    balanceTransferReport:"Api/V1/Member/balanceTransferReport",
    //BANK VERIFICATION
    verifyBank:"Api/V1/Verification/verifyBankDetails",

  //Googleplay operator
  OperatorAndCircleGOOGLEPLAY : "Api/V1/Recharge/getGooglePlayOperator",

  //BBSP API'S
  bbspsOperator: "Api/V1/Bbps/operators/",
  bbpsFormParams: "Api/V1/Bbps/formParameters/",
  fetchBill: "Api/V1/Bbps/fetchBill/",
  paybill: "Api/V1/Bbps/billPayment/",

  //DMT API'S
  submitKyc: "Api/V1/Dmt/submitKyc",
  verifyKycOtp: "Api/V1/Dmt/verifyOtp",
  getRemitters: "Api/V1/Dmt/getRemitter",
  AddRemiter: "Api/V1/Dmt/remitterRegisteration",
  verifyRemitterOtp: "Api/V1/Dmt/verifyRemitterOtp",
  dmtBanks: "Api/V1/Dmt/getDmtBanks",
  benificiaryReg: "Api/V1/Dmt/beneficiaryRegistration",
  verifyBenificiary: "Api/V1/Dmt/verifyBeneficiaryOtp",
  beneficiaryDelete: "Api/V1/Dmt/beneficiaryDelete",
  verifyBeneficiaryDelete: "Api/V1/Dmt/verifyBeneficiaryDeleteOtp",
  fundTransfer: "Api/V1/Dmt/fundTransfer",
  //PAYOUT API'S
  getPayoutBanks: "Api/V1/Payout/getPayoutBanks",
  payoutVerification: "Api/V1/AccountVerification/verifyBankAccount",
  payAmount: "Api/V1/Payout/payout",
  generateQr: "Api/V1/Upi/generateUpi",
  checkUpiPaymentstatus: "Api/V1/Upi/checkUpiPaymentStatus",
  UpiDmt: "Api/V1/UpiDmt/upiDmt",
  // EXPRESSPAYOUT
  getXpressBeneficiary: "Api/V1/XpressPayout/getBeneficiaries",
  XpressPAyout: "Api/V1/XpressPayout/xpressPayout",
  OtpForExpress: "Api/V1/Member/getTransactionOtp",
  //UTI AUTOLOGIN
  utliLogin: "Api/V1/Uti/autoLogin",

  //NSDL SERVICE
  nsdlrequest: "Api/V1/Nsdl/",
  checkPanStatus: "Api/V1/Nsdl/checkPanStatus",

  // KYC REQUEST
  DigioKyc: "Api/V1/DigioKyc/generateDigioRequest",

  //AEPS ONBOARD
  getMasters: "Api/V1/AepsIcici/masters",
  merchantOnboard: "Api/V1/AepsIcici/merchantOnboard",
  sendOtp: "Api/V1/AepsIcici/sendOtp",
  validateOtp: "Api/V1/AepsIcici/validateOtp",
  resendOtp: "Api/V1/AepsIcici/resendOtp",
  biometric: "Api/V1/AepsIcici/biometric",
  twoFactorauth: "Api/V1/AepsIcici/twoFactorAuth",
  cashwithdrawl: "Api/V1/AepsIcici/cashWithdrawl",
  balanceEnquiry: "Api/V1/AepsIcici/balanceEnquiry",
  miniStatement: "Api/V1/AepsIcici/miniStatement",
  aadharPay: "Api/V1/AepsIcici/aadharPay",

  // AEPS YES BANK
  memberUploadDoc: "Api/V1/AepsYesbank/uploadDocument",
  memberkyc: "Api/V1/AepsYesbank/memberKyc",
  getKycData: "Api/V1/AepsYesbank/getKycData",
  getYesMasters: "Api/V1/AepsYesbank/masters",
  yesMerchantOnboard: "Api/V1/AepsYesbank/merchantOnboard",
  yesValidateOtp: "Api/V1/AepsYesbank/validateOtp",
  yesGetAadharWadh: "Api/V1/AepsYesbank/getAadhaarWadh",
  yesBiometric: "Api/V1/AepsYesbank/biometricKyc",
  yesTwoFactorAuth: "Api/V1/AepsYesbank/twoFactorAuth",
  yesCahwithdrawalTwoFa: "Api/V1/AepsYesbank/twoFactorAuthCashWithdrawal",
  yesCashwithdrawl: "Api/V1/AepsYesbank/cashWithdrawal",
  yesBalanceEnquiry: "Api/V1/AepsYesbank/balanceEnquiry",
  yesMiniStatement: "Api/V1/AepsYesbank/miniStatement",
  // MEMBERS
  addmember: "Api/V1/Member/memberSubmit",
  getmemberType: "Api/V1/Member/memberTypes",
  getPackages: "Api/V1/Member/getPackages",
  memberList: "Api/V1/Member/members",

  //REPORTS
  aadharpayReport: "Api/V1/AepsIcici/aadharPayReport",
  miniSattementReport: "Api/V1/AepsIcici/miniStatementReport",
  cashwithdrawlReport: "Api/V1/AepsIcici/cashWithdrawlReport",
  utiReport: "Api/V1/uti/utiReport",
  payoutReport: "Api/V1/Payout/payoutReport",
  dmtReport: "Api/V1/Dmt/dmtReport",
  bbpsreport: "Api/V1/Bbps/bbpsReport",
  rechargeReport: "Api/V1/Recharge/rechargeReport",
  fundRequestReport: "Api/V1/Member/fundRequestReport",
  upiReport: "Api/V1/Upi/upiReport",
  xpressPayoutReport: "Api/V1/XpressPayout/xpressPAyoutReport",
  balanceEnquireyReport: "Api/V1/AepsIcici/balanceEnquiryReport",
  nsdlReport: "Api/V1/Nsdl/nsdlReport",
  yesBankReport: "Api/V1/AepsYesbank/aepsReport",
  upiDmtReport: "Api/V1/UpiDmt/upiDmtReport",
  MAtmReport: "Api/V1/Matm/matmReport",
  balanceTransferReport: "Api/V1/Member/balanceTransferReport",
  //BANK VERIFICATION
  verifyBank: "Api/V1/Verification/verifyBankDetails",

  balanceTransfer: "Api/V1/Member/balanceTransfer",

  serviceComission: "Api/V1/Member/serviceCommissions",
  downLoadCertificate: "Api/V1/Member/certificateDownload",

  setaddtowallet: "/Api/V1/AddFund/AddFundWallet",
  setFundReq:"Api/V1/AddFund/paymentResponse"
};
