import { AutoComplete, Button, Form, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CommonInput from "../../../Common/CommonInput";
import { useParams, useSearchParams } from "react-router-dom";
import {
    dispatchtoast,
    emailPattern,
    filterOption,
    getLocalStorageItem,
    mobilePattern,
    pincodePattern,
    startekPIDOPTS,
} from "../../../Utils";
import {
    XpressPayoutOtp,
    getDmtBanks,
    setFundReq,
    setaddtowallet,
    verifyBankAccount,
} from "../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";







const AddToWallet = () => {
    const [banks, setBanks] = useState([]);
    const profile = useSelector((state) => state.B2B.profileDetail);
    const [verifyBtnLoading, setVerifyBtnLoading] = useState(false)
    const [selectLoading, setselectLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [result, setResult] = useState({ data: null, transaction_id: null });
    const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const [allData, setAllData] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    // const [needToVerify,setNeedtoVerify]=useState(true)
    const formref = useRef();
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const memberServices = useSelector((state) => state.B2B.memberServices);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const message = searchParams.get('message'); // Get 'message' parameter

    const getAllBanks = () => {
        setselectLoading(true);
        getDmtBanks()
            .then((res) => {
                if (res.status) {
                    setBanks(
                        res.data.banks.map((i, index) => {
                            return {
                                label: i.bank_name,
                                value: i.bank_id,
                                key: i?.ifsc_code ? i.ifsc_code : "",
                            };
                        })
                    );
                    setselectLoading(false);
                } else {
                    setBanks([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setselectLoading(false));
    };




    const settAddBankToWallet = (val) => {
        setSpinner(true);
        const formdata = new FormData();
        formdata.append("latitude", getLocalStorageItem("latitude"));
        formdata.append("longitude", getLocalStorageItem("longitude"));
        formdata.append("billing_address", val.billing_address);
        formdata.append("billing_city", val.billing_city);
        formdata.append("billing_email", val.billing_email);
        formdata.append("billing_name", val.billing_name);
        formdata.append("billing_state", val.billing_state);
        formdata.append("billing_tel", val.billing_tel);
        formdata.append("billing_zip", val.billing_zip);
        formdata.append("amount", val.amount);


        setaddtowallet(formdata)
            .then((res) => {
                if (res.status) {
                    dispatchtoast(res.message, false);
                    form.validateFields().then(() => {
                        handlePay(val, res.request_data, res.request_data.encryptedData);
                    });
                } else {
                    dispatchtoast("Failed to initiate payment", true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setSpinner(false));
    };



    // ✅ Handle CCAvenue Payment Redirection
    const handlePay = async (val, requestData) => {
        const {
            accessCode,
            workingKey, // Fetch this securely from backend
            merchantRequestData
        } = requestData;


        if (!requestData.encryptedData || requestData.encryptedData.length < 10) {
            console.error("❌ Encryption Failed! Invalid encrypted data.");
            return;
        }

        // ✅ Create a form dynamically and submit it
        const form = document.createElement("form");
        form.method = "post";
        form.action = "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
        form.style.display = "none";
        form.target = "_blank"; // Open in new tab



        const accessCodeInput = document.createElement("input");
        accessCodeInput.type = "hidden";
        accessCodeInput.name = "access_code";
        accessCodeInput.value = accessCode;
        form.appendChild(accessCodeInput);

        const encRequestInput = document.createElement("input");
        encRequestInput.type = "hidden";
        encRequestInput.name = "encRequest";
        encRequestInput.value = requestData.encryptedData;
        form.appendChild(encRequestInput);

        document.body.appendChild(form);
        form.submit();
    };



    // const checkFundReq = (encResp) => {
    //     setSpinner(true);
    //     const formdata = new FormData();
    //     formdata.append("encResp", encResp);

    //     setFundReq(formdata)
    //         .then((res) => {
    //             if (res.status) {
    //                 dispatchtoast(res.message, false);

    //             } else {
    //                 dispatchtoast("Failed to initiate payment", true);
    //             }
    //         })
    //         .catch((err) => console.log(err))
    //         .finally(() => setSpinner(false));
    // };


    ////////////Solve//////////
    // const combination = (str) => {
    //     let result = [];
    //     const backTracing = (start , string) => {

    //         if(start == str.length){
    //             return;
    //         }

    //         if(start <= str.length){
    //             if(finder(str[start])){

    //             }
    //         }

    //     }
    //     backTracing(0 , str)

    // }

    // const str = "abc";


    // console.log(combination(str))

    ////////////Solve//////////


    return (
        <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
            <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal rounded-3xl p-5">
                <div className="flex justify-between">
                <p className="font-bold text-[15px]">Add To Wallet</p>
                <p className={`${message == "Transaction Failed" ? "text-red-500" : "text-green-500" }`}>{message}</p>
                </div>
            
                <Form
                    name="basic"
                    ref={formref}
                    form={form}
                    onFinish={(val) => settAddBankToWallet(val)}
                >
                    <Spin spinning={spinner}>
                        <Form.Item
                            name="amount"
                            label="Amount"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Amount!" },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value.replace(/\D/g, "");
                                    e.target.value = value;
                                }}
                                placeholder="Enter Amount"
                                name="amount"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_name"
                            label="Billing name"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Billing name!" },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value
                                    e.target.value = value;
                                }}
                                placeholder="Enter Billing name"
                                name="billing_name"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_email"
                            label="Billing email"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Billing email!" },
                                {
                                    pattern: emailPattern,
                                    message: "Invalid email format!",
                                },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value
                                    e.target.value = value;
                                }}
                                placeholder="Enter Billing email"
                                name="billing_email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_tel"
                            label="Billing mobile no."
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Billing mobile no.!" },
                                {
                                    pattern: mobilePattern,
                                    message: "Invalid mobile no format!",
                                },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value.replace(/\D/g, "");
                                    e.target.value = value;
                                }}
                                placeholder="Enter Billing mobile no."
                                name="billing_tel"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_address"
                            label="Billing address"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Billing address!" },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value;
                                    e.target.value = value;
                                }}
                                placeholder="Enter Billing address"
                                name="billing_address"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_state"
                            label="Billing state"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Billing state!" },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value
                                    e.target.value = value;
                                }}
                                placeholder="Enter Billing state"
                                name="billing_state"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_city"
                            label="Billing city"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Billing city!" },
                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value
                                    e.target.value = value;
                                }}
                                placeholder="Enter Billing city"
                                name="billing_city"
                            />
                        </Form.Item>
                        <Form.Item
                            name="billing_zip"
                            label="Zip code"
                            labelCol={{ span: 24 }}
                            rules={[
                                { required: true, message: "Enter Zip code!" },
                                {
                                    pattern: pincodePattern,
                                    message: "Invalid ZIP code format!",
                                },

                                {
                                    whitespace: true,
                                    message: "Field cannot contain only blank spaces",
                                },
                            ]}
                        >
                            <CommonInput
                                onInput={(e) => {
                                    const value = e.target.value.replace(/\D/g, "");
                                    e.target.value = value;
                                }}
                                placeholder="Enter Zip code"
                                name="billing_zip"
                            />
                        </Form.Item>




                    </Spin>
                    <Form.Item>
                        <Button
                            loading={btnLoading}
                            htmlType="submit"
                            className="w-full bg-primary border-none mt-4"
                            style={{ color: "white" }}
                        >
                            Add to wallet
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default AddToWallet;