import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSubHeadings, setselectedTab } from "../../../Store/B2bslice";
import { useLocation, useNavigate } from "react-router-dom";
import { getRemitters } from "../../../ApiServices/Apifun";
import { Spin } from "antd";
import BeneficiaryCard from "./BeneficiaryCard";
import { Images } from "../../../Controller";

const GetRemitter = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [loader, setLoader] = useState(false);
  const checkKyc = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);

  const [remitterDetails, setRemitterDetails] = useState({});
  useEffect(() => {
    dispatch(setSubHeadings(""));
  }, []);
  const handleChange = (e) => {
    const { value } = e.target;
    const sanitized = value.replace(/[^0-9]/g, "");
    const limitedNumbers = sanitized.slice(0, 10);
    setMobile(limitedNumbers);
  };
  const handleSearch = () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("remitter_mobile", mobile);
    getRemitters(formdata)
      .then((res) => {
        if (res.status) {
          setRemitterDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setRemitterDetails({});
      })
      .finally(() => setLoader(false));
  };
  const handleAdd = () => {
    // throw new Error("error occured")
    navigate("/dmt/addRemitters");
  };

  useEffect(() => {
    dispatch(setselectedTab("4"));
    if (!location.state?.allow) {
      if (memberServices.length !== 0) {
        if (!memberServices.includes("4")) {
          navigate("/not-Available");
        } else if (checkKyc.dmt_kyc === "0") {
          navigate("/dmt/submitKyc");
        }
      } else {
        navigate("/not-Available");
      }
    }
  }, []);

  return (
    <>
      <Spin spinning={loader}>
        <p className="font-bold text-xl font-sans">Remitter Search</p>
        <div className="rounded-md mt-2 px-2 h-14  md:w-1/2 flex justify-between items-center  bg-inputbg ">
          <input
            onChange={handleChange}
            value={mobile}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                mobile && handleSearch();
              }
            }}
            placeholder="Search here..."
            className="outline-none border-none w-full px-4 text-sm bg-inputbg "
          />
          <SearchOutlined
            onClick={() => mobile && handleSearch()}
            className={`text-sm ${
              mobile ? "cursor-pointer" : "cursor-not-allowed"
            } bg-bsgray600 text-bswhite border border-bsgray500 rounded-full w-10 h-9 flex justify-center items-center`}
          />
        </div>
        {Object.keys(remitterDetails).length === 0 ? (
          <div className="flex flex-col justify-center mt-32 items-center">
            <img src={Images.nodata} alt="noRemitter" />
            <p className="font-semibold text-sm ">No Remitter Found</p>
            <p
              onClick={handleAdd}
              className="bg-primary cursor-pointer hover:bg-primaryhover transition-all text-bswhite p-4 mt-2 rounded-3xl font-semibold"
            >
              + Add Remitter
            </p>
          </div>
        ) : (
          <>
            <div className="bg-bswhite text-xs animate-fade-in font-semibold mt-2 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 w-full place-items-start gap-2 border border-primary  p-4 rounded-md ">
              <div>
                <p>Name</p>
                <p>
                  {remitterDetails?.first_name +
                    " " +
                    remitterDetails?.last_name}
                </p>
              </div>
              <div>
                <p>Limit Available</p>
                <p className="text-green-500">
                  {remitterDetails?.limit_available}
                </p>
              </div>
              <div>
                <p>Limit Consumed</p>
                <p className="text-red-500">
                  {remitterDetails?.limit_consumed}
                </p>
              </div>
              <div>
                <p>Mobile</p>
                <p>{remitterDetails?.remiiter_mobile}</p>
              </div>
              <div>
                <p>Address</p>
                <p>{remitterDetails?.address?remitterDetails?.address:"--"}</p>
              </div>
            </div>
            <BeneficiaryCard
              handleSearch={handleSearch}
              mobile={mobile}
              remitterDetails={remitterDetails}
            />
          </>
        )}
      </Spin>
    </>
  );
};

export default GetRemitter;
